import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Item = styled.div`
  width: ${props => 100 / props.colCount - 5}%;
  margin-bottom: 50px;
  position: relative;
  text-decoration: none !important;
  @media (max-width: 640px) {
    width: 100% !important;
  }
  ${props => props.colCount === 4 ? css`
    @media (max-width: 960px) {
      width: 48%;
    }` : null};
  > div {
    position: relative;
    border-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
    border: 5px solid;
  }
  img {
    margin-bottom: 0;
  }
  h4 {
    background-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'};
    color: ${props => props.theme.colorMode === 'light' ? '#fff' : '#333'};
    text-transform: uppercase;
    padding: 10px 0;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  h5 {
    font-size: 1.1rem;
    font-weight: 300;
    text-align: left;
  }
  p {
    text-align: left;
    font-size: 0.9rem;
    margin-top: 15px;
  }
  a {
    position: absolute;
    bottom: -42px;
  }
`;
