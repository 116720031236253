import React, { useContext } from 'react';
import PropTypes             from 'prop-types';
import Img                   from 'gatsby-image';

import { findT }                     from '@interness/web-core/src/components/_helpers';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import useDisplayBrands              from '@interness/brands-addon/src/hooks/useDisplayBrands';
import routes                        from '@interness/web-core/config/routes';
import { LanguageContext }     from '@interness/web-core/src/providers';
import { getRandomBrandImage } from '@interness/brands-addon/src/components/_helpers';

import * as S from './styles';

const BrandsDisplay = ({ exclude, order }) => {
  const { displayTypes, brands } = useDisplayBrands(exclude, order);
  const { language } = useContext(LanguageContext);
  return (
    <S.Container>
      {displayTypes.map(displayType => {
        const translations = findT(displayType.translations, 'de');
        const brand = getRandomBrandImage(brands.nodes, displayType.type, 'gallery_images_square');
        return (
          <S.Item colCount={displayTypes.length} key={displayType.type} as={Link} to={routes[displayType.type][language]}>
            <div>
              <Img fluid={brand.gallery_images_square[0].file.localFile.childImageSharp.fluid} alt={brand.display_name}/>
              <h4>{translations.display_name}</h4>
            </div>
          </S.Item>
        )
      })}
    </S.Container>
  )
};

BrandsDisplay.propTypes = {
  exclude: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.arrayOf(PropTypes.string)
};

BrandsDisplay.defaultProps = {
  exclude: [''],
  order: ['']
};

export default BrandsDisplay;
